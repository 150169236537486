// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, AvatarGroup, Tab, Tabs } from "@mui/material";
import { AccessTimeFilled, Cancel, CheckCircle, ManageAccountsOutlined } from "@mui/icons-material";
import breakpoints from "assets/theme/base/breakpoints";
import AdminIcon from "assets/images/bg-profile.jpeg";
import ImageCloud from "../../link";
import moment from "moment"

function daysPassed(startDate, endDate) {
	// Parse the dates to get milliseconds since epoch
	const start = new Date(startDate);
	const end = new Date(endDate);

	// Calculate the difference in milliseconds
	const diffInMs = end - start;

	// Convert milliseconds to days
	const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

	return diffInDays;
}

function Tables({ Token, CheckToken, TableOnly }) {
	const navigate = useNavigate();

	const columns = [
		{
			flex: 1,
			headerName: "status",
			field: "status",
			renderCell: (params) => (
				<MDBadge
					badgeContent={params?.row?.status}
					color={params?.row?.bgColor || "dark"}
					variant="gradient"
					size="sm"
				/>
			)
		},
		{ flex: 1, headerName: "Number", field: "Number" },
		{ flex: 1, headerName: "title", field: "title" },
		{ flex: 1, headerName: "description", field: "description" },
		{
			headerName: "Created At",
			field: "Created At",
			flex: 2,
			valueGetter: ({ row: a }) => `${moment(a?.created_at).format("DD-MM-YYYY hh:mm") || "-"}`
		},
		{
			headerName: "Time Passed",
			field: "Time Passed",
			flex: 2,
			valueGetter: ({ row: a }) => `${Math.round(daysPassed(a?.created_at, new Date())) + " days" || "-"}`
		},
		{
			flex: 1,
			headerName: "ComplainCategory",
			field: "ComplainCategory",
			valueGetter: (params) => `${params.row.ComplainCategory?.title || "-"}`
		},
		{
			flex: 1,
			headerName: "Property",
			field: "Property",
			valueGetter: (params) => `${params.row.Property?.FlatNo || "-"}`
		},
		{
			flex: 1,
			headerName: "Assigned To",
			field: "AssignedTo",
			renderCell: (params) => (
				<div>
					{params?.row?.Tasks?.length > 0 ? (
						<AvatarGroup max={4}>
							{params?.row?.Tasks?.map((a) => (
								<Avatar
									alt="Remy Sharp"
									src={
										a?.assignedTo?.profilePicture?.filename
											? `${ImageCloud}/${a?.assignedTo?.profilePicture?.filename}`
											: AdminIcon
									}
									sx={{ width: 30, height: 30 }}
								/>
							))}
						</AvatarGroup>
					) : (
						"-"
					)}
				</div>
			)
		},
		{
			flex: 1,
			headerName: "Action",
			field: "action",
			renderCell: (params) => (
				<MDBadge
					badgeContent={"Edit"}
					color={"warning"}
					variant="gradient"
					size="sm"
					onClick={() => {
						navigate(
							`/ComplainAction/${params.row?._id}/${params.row?.ComplainCategory?._id}/${params.row?.Property?._id}`
						);
					}}
				/>
			)
		}
	];

	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState("All");

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
		 The event listener that's calling the handleTabsOrientation function when resizing the window.
		*/
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => {
		setTabValue(newValue);
	};

	const GetComplains = () => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllComplain`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					if (res?.data?.status == 200) {
						const arr = res?.data?.data
							.filter((a) => {
								const Time = (new Date() - new Date(a?.created_at)) / (1000 * 60 * 60);

								if (Time >= 72) {
									a.bgColor = "error";
								} else if (Time >= 48) {
									a.bgColor = "warning";
								} else if (Time >= 24) {
									a.bgColor = "secondary";
								} else {
									a.bgColor = "primary";
								}

								if (tabValue == "All") {
									return a;
								} else if (
									tabValue == "Active" &&
									(a?.status == "active" || a?.status == "inactive" || a?.status == "delayed")
								) {
									return a;
								} else if (tabValue == "Completed" && a?.status == "completed") {
									a.bgColor = "success";
									return a;
								} else if (
									tabValue == "Canceled" &&
									(a?.status == "canceled" || a?.status == "canceledByUser")
								) {
									a.bgColor = "error";
									return a;
								}
							})
							.sort((a, b) => {
								const timeA = (new Date() - new Date(a?.created_at)) / (1000 * 60 * 60);
								const timeB = (new Date() - new Date(b?.created_at)) / (1000 * 60 * 60);

								// Sorting condition: >=72 on top, then >=48, then >=24
								if (timeA >= 72 && timeB >= 72) {
									return timeA - timeB;
								} else if (timeA >= 48 && timeB >= 48) {
									return timeA - timeB;
								} else if (timeA >= 24 && timeB >= 24) {
									return timeA - timeB;
								} else {
									// Default sorting if none of the conditions match
									return 0;
								}
							});

						setData(arr);
					} else {
						setError(res?.data?.message);
					}
				})
				.catch((err) => {
					setError(err?.message);
				});
		} else {
			setTimeout(() => {
				CheckToken();
				GetComplains();
			}, 500);
		}
	};

	useEffect(() => {
		GetComplains();
	}, []);

	useEffect(() => {
		GetComplains();
	}, [tabValue]);

	const ComplainTable = () => {
		return (
			<Card>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid container style={{ width: "100%" }}>
						<Grid item xs={6}>
							<MDTypography variant="h6" color="white">
								{tabValue} Complain
							</MDTypography>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								alignItems: "flex-end",
								alignContent: "flex-end",
								justifyContent: "flex-end"
							}}
						>
							<MDBadge
								badgeContent="New"
								color="dark"
								variant="gradient"
								size="sm"
								onClick={() => navigate(`/ComplainCategorySelect/New`)}
							/>
						</Grid>
					</Grid>
				</MDBox>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid
						container
						style={{
							width: "100%",
							alignItems: "flex-end",
							alignContent: "flex-end",
							justifyContent: "flex-end"
						}}
					>
						<Grid item xs={12}>
							<Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
								<Tab
									label="All"
									value={"All"}
									icon={<ManageAccountsOutlined fontSize="small" sx={{ mt: -0.25 }} />}
								/>
								<Tab
									label="Active"
									value={"Active"}
									icon={<AccessTimeFilled fontSize="small" sx={{ mt: -0.25 }} />}
								/>
								<Tab
									label="Completed"
									value={"Completed"}
									icon={<CheckCircle fontSize="small" sx={{ mt: -0.25 }} />}
								/>
								<Tab
									label="Canceled"
									value={"Canceled"}
									icon={<Cancel fontSize="small" sx={{ mt: -0.25 }} />}
								/>
							</Tabs>
						</Grid>
					</Grid>
				</MDBox>

				<MDBox pt={3}>
					{data.length > 0 ? (
						<DataTable rows={data} columns={columns} />
					) : (
						<MDBox lineHeight={1} textAlign="center">
							<MDTypography
								display="block"
								variant="caption"
								color="text"
								fontWeight="medium"
								fontSize="20px"
							>
								{error ? error : " No Data Found"}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</Card>
		);
	};

	if (TableOnly) {
		return <ComplainTable />;
	} else {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<ComplainTable />
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
		);
	}
}

export default withAuthContext(Tables);
