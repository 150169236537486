/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceContext } from "context/Service";
import { useEffect } from "react";
import Loader from "components/Loader";

function Details({
	stateService,
	handleSubmitService,
	handleChangeService,
	serviceId,
	DataGetService,
	LoadingService
}) {
	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				{!DataGetService?.Payment?._id && (
					<MDButton
						variant="gradient"
						color="dark"
						onClick={() => {
							handleSubmitService();
						}}
					>
						{serviceId !== "New" ? "update" : "Add"}
					</MDButton>
				)}
			</MDBox>
			<MDBox pt={2} px={2} display="flex" justifyContent="center" alignItems="center">
				<MDTypography variant="h6" color="black">
					{DataGetService?.ServiceOffered?.title?.toUpperCase()}
				</MDTypography>
			</MDBox>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							status
						</InputLabel>
						<Select
							value={stateService?.status}
							label="status"
							variant="standard"
							fullWidth
							disabled={DataGetService?.Payment?._id}
							onChange={(e) => handleChangeService("status", e.target.value)}
							style={{ textTransform: "uppercase" }}
						>
							<MenuItem value={"active"}>active</MenuItem>
							<MenuItem value={"done"}>done</MenuItem>
							<MenuItem value={"inProgress"}>inProgress</MenuItem>
							<MenuItem value={"inactive"}>inactive</MenuItem>
							<MenuItem value={"canceled"}>canceled</MenuItem>
							<MenuItem value={"delayed"}>delayed</MenuItem>
							<MenuItem value={"canceledByUser"}>canceledByUser</MenuItem>
							{DataGetService?.Bill?._id && <MenuItem value={"completed"}>completed</MenuItem>}
						</Select>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							value={stateService?.description}
							label="description"
							variant="standard"
							fullWidth
							disabled={serviceId != "New"}
							onChange={(e) => handleChangeService("description", e.target.value)}
						/>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							value={stateService?.reason}
							label="reason"
							variant="standard"
							fullWidth
							disabled={DataGetService?.Payment?._id}
							onChange={(e) => handleChangeService("reason", e.target.value)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
}

export default withServiceContext(Details);
